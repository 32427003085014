import {NgModule} from '@angular/core';
import {DropDownComponent} from './drop-down.component';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {DropDownOptionComponent} from './drop-down-option/drop-down-option.component';

@NgModule({
	declarations: [DropDownComponent, DropDownOptionComponent],
	imports: [CommonModule, FormsModule],
	exports: [DropDownComponent, DropDownOptionComponent]
})

export class DropDownModule {
}
